import Swap from 'views/Swap'
import { getBalanceAmount } from 'utils/formatBalance'
import { formatNumber } from 'helpers/formatNumber'
import useGetLpContract from './hooks/useGetLpContract'
import useGetCryptoPrice from './hooks/useGetCryptoPrice'

const supply = 120000000

const HomePage = () => {
  const { rewards } = useGetLpContract()
  const { USD } = useGetCryptoPrice()
  const totalMeme = getBalanceAmount(rewards[1]?.toString())
  const totalETH = getBalanceAmount(rewards[0]?.toString())
  const totalLpinUSD = ((USD * totalETH.toNumber()) / totalMeme.toNumber()) * supply

  // console.log(rewards)
  // console.log(rewards[0]?.toString())
  // console.log(rewards[1]?.toString())
  // console.log('totalMeme', totalMeme.toNumber())
  // console.log('totalETH', totalETH.toNumber())
  // console.log('totalLpinUSD', totalLpinUSD)
  // console.log('totalLpinUSD format', formatNumber(totalLpinUSD))

  return (
    <>
      <Swap totalSupply={formatNumber(supply, 0)} mcap={formatNumber(totalLpinUSD)} />
    </>
  )
}

export default HomePage
