import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { CurrencyAmount, ETHER, JSBI, Token, Trade } from '@meme-dex/sdk'
import {
  Button,
  Text,
  Box,
  useModal,
  Flex,
  IconButton,
  SwitchIcon,
  useMatchBreakpoints,
  NotificationDot,
  ArrowDownIcon,
  ChevronDownIcon,
} from 'packages/uikit'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/UnsupportedCurrencyFooter'
// import Footer from 'components/Menu/Footer'
import { useRouter } from 'next/router'
import { useTranslation } from 'contexts/Localization'
// import { EXCHANGE_DOCS_URLS } from 'config/constants'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from 'utils/prices'
import shouldShowSwapWarning from 'utils/shouldShowSwapWarning'
import { BodyWrapper } from 'components/App/AppBody'
import { formatNumber } from 'helpers/formatNumber'
import useRefreshBlockNumberID from './hooks/useRefreshBlockNumber'
import AddressInputPanel from './components/AddressInputPanel'
// import { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Layout/Column'
import ConfirmSwapModal from './components/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { AutoRow, RowBetween } from '../../components/Layout/Row'
import AdvancedSwapDetailsDropdown from './components/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from './components/confirmPriceImpactWithoutFee'
import { ArrowWrapper, SwapCallbackError, Wrapper } from './components/styleds'
import TradePrice from './components/TradePrice'
// import ProgressSteps from './components/ProgressSteps'
// import { AppBody } from '../../components/App'
import ConnectWalletButton from '../../components/ConnectWalletButton'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
  useSingleTokenSwapInfo,
} from '../../state/swap/hooks'
import {
  useExpertModeManager,
  useUserSlippageTolerance,
  useUserSingleHopOnly,
  useExchangeChartManager,
} from '../../state/user/hooks'
import CircleLoader from '../../components/Loader/CircleLoader'
import Page from '../Page'
import SwapWarningModal from './components/SwapWarningModal'
// import PriceChartContainer from './components/Chart/PriceChartContainer'
// import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles'
// import CurrencyInputHeader from './components/CurrencyInputHeader'
import ImportTokenWarningModal from '../../components/ImportTokenWarningModal'

export default function Swap({ totalSupply, mcap }) {
  const router = useRouter()
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  // const [isChartExpanded, setIsChartExpanded] = useState(false)
  const [userChartPreference, setUserChartPreference] = useExchangeChartManager(isMobile)
  const [isChartDisplayed, setIsChartDisplayed] = useState(userChartPreference)
  const { refreshBlockNumber, isLoading } = useRefreshBlockNumberID()
  const [isShow, setIsShow] = useState<boolean>(false)

  const onShow = () => setIsShow((prev) => !prev)

  useEffect(() => {
    setUserChartPreference(isChartDisplayed)
  }, [isChartDisplayed, setUserChartPreference])

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency],
  )

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !(token.address in defaultTokens)
    })

  const { account } = useActiveWeb3React()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state & price data
  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(independentField, typedValue, inputCurrency, outputCurrency, recipient)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade

  const singleTokenPrice = useSingleTokenSwapInfo(inputCurrencyId, inputCurrency, outputCurrencyId, outputCurrency)

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  // modal and loading
  const [{ tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0)),
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const maxAmountOutput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.OUTPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee, t)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then((hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, swapErrorMessage: undefined, txHash: hash })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [priceImpactWithoutFee, swapCallback, tradeToConfirm, t])

  // errors
  // const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn })
  }, [attemptingTxn, swapErrorMessage, trade, txHash])

  // swap warning state
  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null)
  const [onPresentSwapWarningModal] = useModal(<SwapWarningModal swapCurrency={swapWarningCurrency} />, false)

  useEffect(() => {
    if (swapWarningCurrency) {
      onPresentSwapWarningModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapWarningCurrency])

  const handleInputSelect = useCallback(
    (currencyInput) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, currencyInput)
      const showSwapWarning = shouldShowSwapWarning(currencyInput)
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyInput)
      } else {
        setSwapWarningCurrency(null)
      }
    },
    [onCurrencySelection],
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toSignificant(6))
    }
  }, [maxAmountInput, onUserInput])

  const handleMaxOutput = useCallback(() => {
    if (maxAmountOutput) {
      onUserInput(Field.OUTPUT, maxAmountOutput.toSignificant(6))
    }
  }, [maxAmountOutput, onUserInput])

  const handleOutputSelect = useCallback(
    (currencyOutput) => {
      onCurrencySelection(Field.OUTPUT, currencyOutput)
      const showSwapWarning = shouldShowSwapWarning(currencyOutput)
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyOutput)
      } else {
        setSwapWarningCurrency(null)
      }
    },

    [onCurrencySelection],
  )

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const [onPresentImportTokenWarningModal] = useModal(
    <ImportTokenWarningModal tokens={importTokensNotInDefault} onCancel={() => router.push('/swap')} />,
  )

  useEffect(() => {
    if (importTokensNotInDefault.length > 0) {
      onPresentImportTokenWarningModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importTokensNotInDefault.length])

  const [onPresentConfirmModal] = useModal(
    <ConfirmSwapModal
      trade={trade}
      originalTrade={tradeToConfirm}
      onAcceptChanges={handleAcceptChanges}
      attemptingTxn={attemptingTxn}
      txHash={txHash}
      recipient={recipient}
      allowedSlippage={allowedSlippage}
      onConfirm={handleSwap}
      swapErrorMessage={swapErrorMessage}
      customOnDismiss={handleConfirmDismiss}
    />,
    true,
    true,
    'confirmSwapModal',
  )

  const hasAmount = Boolean(parsedAmount)

  const onRefreshPrice = useCallback(() => {
    if (hasAmount) {
      refreshBlockNumber()
    }
  }, [hasAmount, refreshBlockNumber])

  const isBuy = inputCurrencyId === ETHER.symbol
  const isBuyText = isBuy ? 'Buy now' : 'Sell now'
  const errorText = swapInputError || isBuyText
  const formatDecimal = isBuy ? 2 : 4
  const [expertMode] = useExpertModeManager()

  return (
    <PositionTest>
      {/* <CurrencyInputHeader
        title={t('Swap')}
        subtitle={t('')}
        setIsChartDisplayed={setIsChartDisplayed}
        isChartDisplayed={isChartDisplayed}
        hasAmount={hasAmount}
        onRefreshPrice={onRefreshPrice}
      /> */}
      <Box width="100%" position="relative" zIndex="9">
        <Box maxWidth="616px" width="100%" mx="auto" position="relative">
          <SwapBox width="100%" justifyContent="center">
            <SettingPositionIcon>
              <NotificationDot show={expertMode}>
                <GlobalSettings color="var(--color-black)" mr="0" />
              </NotificationDot>
            </SettingPositionIcon>
            <Flex width="100%" flexDirection="column">
              <Box>
                <Wrapper id="swap-page">
                  <Flex alignItems="center" justifyContent="center" mb="16px">
                    <Text fontSize={['16px', , , , '25px']} lineHeight="1">
                      {t('Swap %input% to %output% for MoodengFi', {
                        input: currencies[Field.INPUT]?.symbol,
                        output: currencies[Field.OUTPUT]?.symbol,
                      })}
                    </Text>

                    <AutoColumn justify="space-between">
                      <AutoRow justify="center" style={{ marginBottom: '0' }}>
                        <SwitchIconButton
                          scale="sm"
                          onClick={() => {
                            setApprovalSubmitted(false) // reset 2 step UI for approvals
                            onSwitchTokens()
                          }}
                        >
                          <SwitchIcon width={isMobile ? '10px' : '13px'} />
                        </SwitchIconButton>
                      </AutoRow>
                    </AutoColumn>
                  </Flex>

                  <AutoColumn gap="md" style={{ marginBottom: '16px' }}>
                    <CurrencyInputPanel
                      label={
                        independentField === Field.OUTPUT && !showWrap && trade ? t('From (estimated)') : t('From')
                      }
                      value={formattedAmounts[Field.INPUT]}
                      showMaxButton={!atMaxAmountInput}
                      currency={currencies[Field.INPUT]}
                      onUserInput={handleTypeInput}
                      onMax={handleMaxInput}
                      onCurrencySelect={handleInputSelect}
                      otherCurrency={currencies[Field.OUTPUT]}
                      // disableCurrencySelect
                      id="swap-currency-input"
                    />

                    {recipient === null && !showWrap && isExpertMode ? (
                      <Button variant="text" id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                        <Text>{t('+ Add a send (optional)')}</Text>
                      </Button>
                    ) : null}

                    <CurrencyInputPanel
                      value={formattedAmounts[Field.OUTPUT]}
                      onUserInput={handleTypeOutput}
                      label={independentField === Field.INPUT && !showWrap && trade ? t('To (estimated)') : t('To')}
                      showMaxButton={false}
                      onMax={handleMaxOutput}
                      currency={currencies[Field.OUTPUT]}
                      onCurrencySelect={handleOutputSelect}
                      otherCurrency={currencies[Field.INPUT]}
                      // disableCurrencySelect
                      id="swap-currency-output"
                    />

                    {isExpertMode && recipient !== null && !showWrap ? (
                      <>
                        <AutoRow justify="space-between" style={{ padding: '0' }}>
                          <ArrowWrapper clickable={false}>
                            <ArrowDownIcon color="black" width="30px" height="30px" />
                          </ArrowWrapper>
                          <Button
                            variant="text"
                            id="remove-recipient-button"
                            px="0"
                            onClick={() => onChangeRecipient(null)}
                          >
                            <Text>{t('- Remove send')}</Text>
                          </Button>
                        </AutoRow>
                        <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                      </>
                    ) : null}

                    {/* {showWrap ? null : (
                        <AutoColumn gap="15px" style={{ padding: '0' }}>
                          <RowBetween align="center">
                            {Boolean(trade) && (
                              <>
                                <Label>{t('Price')}</Label>
                                {isLoading ? (
                                  <Skeleton width="100%" ml="8px" height="24px" />
                                ) : (
                                  <TradePrice
                                    price={trade?.executionPrice}
                                    showInverted={showInverted}
                                    setShowInverted={setShowInverted}
                                  />
                                )}
                              </>
                            )}
                          </RowBetween>
                          <RowBetween align="center">
                            <Label>{t('Slippage Tolerance')}</Label>
                            <Text>{allowedSlippage / 100}%</Text>
                          </RowBetween>
                        </AutoColumn>
                      )} */}
                  </AutoColumn>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    as={Button}
                    variant="text"
                    px="0"
                    onClick={onShow}
                    disabled={!trade}
                  >
                    <Text fontSize={['16px', , , , '26px']} color="var(--color-black)">
                      {t('You will receive: %amount% %symbol%', {
                        amount: formatNumber(formattedAmounts[Field.OUTPUT], formatDecimal),
                        symbol: currencies[Field.OUTPUT]?.symbol,
                      })}
                    </Text>

                    <ChevronDownIcon color="black" />
                  </Flex>

                  {!swapIsUnsupported ? (
                    trade && isShow && <AdvancedSwapDetailsDropdown trade={trade} />
                  ) : (
                    <UnsupportedCurrencyFooter currencies={[currencies.INPUT, currencies.OUTPUT]} />
                  )}

                  <Box mt="0.25rem">
                    {swapIsUnsupported ? (
                      <StyledButton width="100%" disabled>
                        <StyledText>{t('Unsupported Asset')}</StyledText>
                      </StyledButton>
                    ) : !account ? (
                      <ConnectWalletButton width="100%" minHeight="50px">
                        <StyledText>{t('Connect Wallet')}</StyledText>
                      </ConnectWalletButton>
                    ) : showWrap ? (
                      <StyledButton width="100%" disabled={Boolean(wrapInputError)} onClick={onWrap}>
                        <StyledText>
                          {wrapInputError ??
                            (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                        </StyledText>
                      </StyledButton>
                    ) : noRoute && userHasSpecifiedInputOutput ? (
                      <StyledButton width="100%" disabled>
                        <StyledText>{t('Insufficient liquidity for this trade.')}</StyledText>
                        {singleHopOnly && <StyledText>{t('Try enabling multi-hop trades.')}</StyledText>}
                      </StyledButton>
                    ) : showApproveFlow ? (
                      <RowBetween style={{ gap: '6px' }}>
                        <StyledButton
                          variant="primary"
                          onClick={approveCallback}
                          disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                          width="100%"
                        >
                          <StyledText>
                            {approval === ApprovalState.PENDING ? (
                              <StyledText>
                                {t('Enabling')} <CircleLoader stroke="white" />
                              </StyledText>
                            ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                              t('Enabled')
                            ) : (
                              t('Enable %asset%', { asset: currencies[Field.INPUT]?.symbol ?? '' })
                            )}
                          </StyledText>
                        </StyledButton>
                        <StyledButton
                          // variant={isValid && priceImpactSeverity > 2 ? 'danger' : 'primary'}
                          variant={isBuy ? 'primary' : 'sell'}
                          width="100%"
                          onClick={() => {
                            if (isExpertMode) {
                              handleSwap()
                            } else {
                              setSwapState({
                                tradeToConfirm: trade,
                                attemptingTxn: false,
                                swapErrorMessage: undefined,
                                txHash: undefined,
                              })
                              onPresentConfirmModal()
                            }
                          }}
                          id="swap-button"
                          disabled={
                            !isValid ||
                            approval !== ApprovalState.APPROVED ||
                            (priceImpactSeverity > 3 && !isExpertMode) ||
                            attemptingTxn
                          }
                        >
                          <StyledText color={swapInputError ? 'var(--color-error)' : 'var(--color-text)'}>
                            {priceImpactSeverity > 3 && !isExpertMode
                              ? errorText
                              : priceImpactSeverity > 2
                              ? isBuyText
                              : isBuyText}
                          </StyledText>
                        </StyledButton>
                      </RowBetween>
                    ) : (
                      <StyledButton
                        // variant={isValid && priceImpactSeverity > 2 && !swapCallbackError ? 'danger' : 'primary'}
                        // variant={isValid && priceImpactSeverity > 2 && !swapCallbackError && isBuy ? 'primary' : 'sell'}
                        variant={isBuy ? 'primary' : 'sell'}
                        onClick={() => {
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              txHash: undefined,
                            })
                            onPresentConfirmModal()
                          }
                        }}
                        id="swap-button"
                        width="100%"
                        disabled={
                          !isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError || attemptingTxn
                        }
                      >
                        <StyledText color={swapInputError ? 'var(--color-error)' : 'var(--color-text)'}>
                          {swapInputError || (priceImpactSeverity > 3 && !isExpertMode)
                            ? errorText
                            : priceImpactSeverity > 2
                            ? isBuyText
                            : isBuyText}
                        </StyledText>
                      </StyledButton>
                    )}
                    {/* {showApproveFlow && (
                    <Column style={{ marginTop: '1rem' }}>
                      <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                    </Column>
                  )}
                  {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null} */}
                  </Box>
                </Wrapper>
              </Box>
            </Flex>
          </SwapBox>
          <McapWrapper>
            <div className="mcap">
              <Flex flexDirection="column" style={{ gap: '0px' }} mb="8px">
                <Text textAlign="center" fontSize={['18px', , , , '20px']}>
                  Supply
                </Text>
                <Text textAlign="center" fontSize={['18px', , , , '20px']} lineHeight="1">
                  {totalSupply} MDF
                </Text>
              </Flex>

              <Flex flexDirection="column" style={{ gap: '0px' }}>
                <Text textAlign="center" fontSize={['18px', , , , '20px']}>
                  Mcap
                </Text>
                <Text textAlign="center" fontSize={['18px', , , , '20px']} lineHeight="1">
                  ${mcap}
                </Text>
              </Flex>
            </div>
          </McapWrapper>
        </Box>
      </Box>
    </PositionTest>
  )
}

const SwitchIconButton = styled(IconButton)`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--color-pink);

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 24px;
    height: 24px;
  }

  .icon-up-down {
    display: none;
  }
  &:hover {
    opacity: 0.8;
  }
`

const PositionTest = styled(Page)``

const SwapBox = styled(Flex).attrs({
  background: 'var(--color-white)',
  borderRadius: '20px',
  padding: ['12px', , , , '0'],
  position: 'relative',
})`
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 1);
`

const StyledAppBody = styled(BodyWrapper)``

const StyledButton = styled(Button)`
  min-height: 50px;
  position: relative;
  z-index: 2;
`

const StyledText = styled(Text).attrs({ fontSize: ['20px', , , , '25px'] })``

const McapWrapper = styled(Box)`
  position: relative;
  max-width: 487px;
  width: 100%;
  aspect-ratio: calc(487 / 340);
  height: auto;

  background: url('/images/mcap-bg.svg') no-repeat;
  background-size: 100%;

  ${({ theme }) => theme.mediaQueries.lg} {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    transform: translate(calc(-25% + 15px), calc(100% - 55px));
  }

  div {
    &.mcap {
      position: absolute;
      bottom: calc(20% - 5px);
      right: 15%;

      width: 100%;
      max-width: clamp(210px, 5vw, 286px);
      aspect-ratio: calc(285.28 / 61.11);
      height: auto;

      ${({ theme }) => theme.mediaQueries.lg} {
        bottom: calc(20% + 10px);
        right: 22%;
      }
    }
  }
`

export const SettingPositionIcon = styled.div`
  position: absolute;
  z-index: 9;
  top: 5px;
  right: 5px;
`

export const Label = styled(Text)``
