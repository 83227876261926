/* eslint-disable import/no-useless-path-segments */
import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 13 13" {...props}>
      <path d="M1.75 11.875H0.625V8.5H4V9.625H2.605C3.51062 10.9806 5.0575 11.875 6.8125 11.875C9.60812 11.875 11.875 9.60812 11.875 6.8125H13C13 10.2325 10.2325 13 6.8125 13C4.72 13 2.86937 11.9594 1.75 10.3731V11.875ZM0.625 6.8125C0.625 3.3925 3.3925 0.625 6.8125 0.625C8.905 0.625 10.7556 1.66563 11.875 3.25187V1.75H13V5.125H9.625V4H11.02C10.1144 2.64437 8.5675 1.75 6.8125 1.75C4.01688 1.75 1.75 4.01688 1.75 6.8125H0.625Z" />
    </Svg>
  )
}

export default Icon
