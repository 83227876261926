import React, { useState, useEffect } from 'react'

function useGetCryptoPrice() {
  const [price, setPrice] = useState<{ BTC: number; USD: number } | null>(null)
  const [error, setError] = useState<string | null>(null)

  const fetchCryptoPrice = async () => {
    try {
      const response = await fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=BTC,USD')

      if (!response.ok) {
        throw new Error('Failed to fetch crypto prices')
      }

      const data = await response.json()
      setPrice(data)
    } catch (err) {
      setError('Error fetching data')
      console.error(err)
    }
  }

  useEffect(() => {
    fetchCryptoPrice()

    const intervalId = setInterval(fetchCryptoPrice, 5000)

    return () => clearInterval(intervalId)
  }, [])

  return { USD: price?.USD }
}

export default useGetCryptoPrice
