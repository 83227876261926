import { Trade, TradeType } from '@meme-dex/sdk'
import { Text, Box } from 'packages/uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from 'contexts/Localization'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from 'utils/prices'
import { AutoColumn } from 'components/Layout/Column'
import QuestionHelper from 'components/QuestionHelper'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import { useState } from 'react'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'
import { TradeTextColor } from './styleds'
import TradePrice from './TradePrice'

function TradeSummary({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const { t } = useTranslation()
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)

  return (
    <AutoColumn style={{ padding: '0' }}>
      <RowBetween>
        <RowFixed>
          <TradeTextColor color="var(--color-black)">{t('Slippage Tolerance')}</TradeTextColor>
          <QuestionHelper
            text={t('The difference between the market price and estimated price due to trade size.')}
            ml="4px"
            placement="top-start"
            color="var(--color-white)"
          />
        </RowFixed>
        <TradeTextColor color="rgba(204, 88, 96, 1)">{allowedSlippage / 100}%</TradeTextColor>
      </RowBetween>
      <RowBetween>
        <RowFixed>
          <TradeTextColor color="var(--color-black)">{t('Price Impact')}</TradeTextColor>
          <QuestionHelper
            text={t('The difference between the market price and estimated price due to trade size.')}
            ml="4px"
            placement="top-start"
            color="var(--color-white)"
          />
        </RowFixed>
        <FormattedPriceImpact
          fontSize={['14px', , , , '16px']}
          color="rgba(204, 88, 96, 1)"
          priceImpact={priceImpactWithoutFee}
        />
      </RowBetween>
      <RowBetween>
        <RowFixed>
          <TradeTextColor color="var(--color-black)">
            {isExactIn ? t('Minimum received') : t('Maximum sold')}
          </TradeTextColor>
          <QuestionHelper
            text={t(
              'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
            )}
            ml="4px"
            placement="top-start"
            iconcolor="var(--color-black)"
          />
        </RowFixed>
        <RowFixed>
          <TradeTextColor color="var(--color-black)">
            {isExactIn
              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ||
                '-'
              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` || '-'}
          </TradeTextColor>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <RowFixed>
          <TradeTextColor color="var(--color-black)">{t('Liquidity Provider Fee')}</TradeTextColor>
          <QuestionHelper
            text={
              <>
                <Box mb="12px">{t('For each trade a %amount% fee is paid', { amount: '0.25%' })}</Box>
                <Box>- {t('%amount% to LP token holders', { amount: '0.17%' })}</Box>
                <Box>- {t('%amount% to the Treasury', { amount: '0.03%' })}</Box>
                <Box>- {t('%amount% towards ABS buyback and burn', { amount: '0.05%' })}</Box>
              </>
            }
            ml="4px"
            placement="top-start"
            color="var(--color-white)"
          />
        </RowFixed>
        <TradeTextColor color="var(--color-black)">
          {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
        </TradeTextColor>
      </RowBetween>
    </AutoColumn>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const { t } = useTranslation()
  const [allowedSlippage] = useUserSlippageTolerance()
  const showRoute = Boolean(trade && trade.route.path.length > 2)
  const [showInverted, setShowInverted] = useState<boolean>(false)

  return (
    <AutoColumn gap="0px">
      {trade && (
        <>
          <RowBetween>
            <RowFixed>
              <TradeTextColor fontSize="14px" color="var(--color-black)">
                {t('Rate')}
              </TradeTextColor>
            </RowFixed>
            <TradePrice price={trade?.executionPrice} showInverted={showInverted} setShowInverted={setShowInverted} />
          </RowBetween>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <RowBetween style={{ padding: '0 16px' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <TradeTextColor fontSize="14px" color="var(--color-black)">
                    {t('Route')}
                  </TradeTextColor>
                  <QuestionHelper
                    text={t('Routing through these tokens resulted in the best price for your trade.')}
                    ml="4px"
                    placement="top-start"
                  />
                </span>
                <SwapRoute trade={trade} />
              </RowBetween>
            </>
          )}
        </>
      )}
    </AutoColumn>
  )
}
