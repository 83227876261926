import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10 13.575C10.1333 13.575 10.2583 13.554 10.375 13.512C10.4917 13.4707 10.6 13.4 10.7 13.3L13.3 10.7C13.4833 10.5167 13.575 10.2833 13.575 10C13.575 9.71667 13.4833 9.48333 13.3 9.3C13.1167 9.11667 12.8833 9.025 12.6 9.025C12.3167 9.025 12.0833 9.11667 11.9 9.3L11 10.2V7C11 6.71667 10.9043 6.479 10.713 6.287C10.521 6.09567 10.2833 6 10 6C9.71667 6 9.47933 6.09567 9.288 6.287C9.096 6.479 9 6.71667 9 7V10.2L8.1 9.3C7.91667 9.11667 7.68333 9.025 7.4 9.025C7.11667 9.025 6.88333 9.11667 6.7 9.3C6.51667 9.48333 6.425 9.71667 6.425 10C6.425 10.2833 6.51667 10.5167 6.7 10.7L9.3 13.3C9.4 13.4 9.50833 13.4707 9.625 13.512C9.74167 13.554 9.86667 13.575 10 13.575ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z"
        fill="#FCB0B5"
      />
    </Svg>
  )
}

export default Icon
