import { Currency, Pair, Token, JSBI } from '@meme-dex/sdk'
import {
  Button,
  ChevronDownIcon,
  Text,
  useModal,
  Flex,
  Box,
  MetamaskIcon,
  MetamaskWallet,
  useMatchBreakpoints,
} from 'packages/uikit'
import styled from 'styled-components'
import { registerToken } from 'utils/wallet'
import { isAddress } from 'utils'
import { useTranslation } from 'contexts/Localization'
import { WrappedTokenInfo } from 'state/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { FlexGap } from 'components/Layout/Flex'
import { formatNumber } from 'helpers/formatNumber'
import { useState } from 'react'
import BigNumber from 'bignumber.js'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'
import { Input as NumericalInput } from './NumericalInput'
import { CopyButton } from '../CopyButton'

const PERCENT_BUTTONS = [
  { label: '25%', value: 25 },
  { label: '50%', value: 50 },
  // { label: '75%', value: 75 },
  { label: 'Max', value: 100 },
]

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  justifyContent?: 'center' | 'space-between' | 'space-evenly' | 'flex-start' | 'flex-end'
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
  justifyContent = 'center',
  ...props
}: CurrencyInputPanelProps) {
  const { account, library } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const decimal = selectedCurrencyBalance?.greaterThan('1') ? 2 : 6
  const { isMobile } = useMatchBreakpoints()

  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()

  const token = pair ? pair.liquidityToken : currency instanceof Token ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )
  const balance = selectedCurrencyBalance?.toSignificant(6)
  const balanceBN = new BigNumber(balance)
  const [percent, setPercent] = useState(0)

  const handleChangePercent = (percentage: number) => {
    const percentValue = balanceBN.multipliedBy(percentage).dividedBy(100)
    onUserInput(percentValue.toString())
    setPercent(percentage)
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} position="relative" id={id} {...props}>
      <Flex alignItems="center" justifyContent={justifyContent}>
        <Flex alignItems="center" style={{ gap: '4px' }}>
          <CurrencySelectButton
            className="open-currency-select-button"
            selected={!!currency}
            onClick={() => {
              if (!disableCurrencySelect) {
                onPresentCurrencyModal()
              }
            }}
          >
            <ButtonCurrency alignItems="center" justifyContent="space-between">
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
              ) : currency ? (
                <CurrencyLogo currency={currency} size={isMobile ? '20px' : '35px'} />
              ) : null}
              {pair ? (
                <Text color="var(--color-black)" id="pair">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </Text>
              ) : (
                <>
                  <Text id="pair">
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                          currency.symbol.length - 5,
                          currency.symbol.length,
                        )}`
                      : currency?.symbol) || t('Select a currency')}
                  </Text>
                </>
              )}

              {!disableCurrencySelect ? <ChevronDownIcon color="var(--color-black)" /> : null}
            </ButtonCurrency>
          </CurrencySelectButton>

          {token && tokenAddress ? (
            <Flex style={{ gap: '4px' }} alignItems="center">
              <CopyButton
                width="16px"
                buttonColor="var(--color-black)"
                text={tokenAddress}
                tooltipMessage={t('Token address copied')}
                tooltipTop={-30}
                tooltipRight={40}
                tooltipFontSize={12}
              />
              {library?.provider?.isMetaMask && (
                <MetamaskWallet
                  width="26px"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    registerToken(
                      tokenAddress,
                      token.symbol,
                      token.decimals,
                      token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                    )
                  }
                />
              )}
            </Flex>
          ) : null}
        </Flex>
      </Flex>
      <InputPanel>
        <Container>
          <LabelRow>
            <StyledInput
              className="token-amount-input"
              value={value}
              onUserInput={(val) => {
                onUserInput(val)
              }}
            />
          </LabelRow>
          {/* <InputRow selected={disableCurrencySelect} style={{ padding: '0' }}>
            {account && (
              <PositionButton onClick={onMax}>
                <Text color="var(--color-black)">Max</Text>
              </PositionButton>
            )}
          </InputRow> */}
        </Container>
      </InputPanel>
      {account && (
        <>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex>
              {PERCENT_BUTTONS.map((button) => (
                <Button
                  variant="text"
                  height="auto"
                  p="0"
                  key={button.value}
                  onClick={() => handleChangePercent(button.value)}
                >
                  <Text
                    fontSize={['12px', , , , '16px']}
                    color={percent === button.value ? 'rgba(204, 88, 96, 1)' : 'var(--color-text)'}
                  >
                    {button.label}
                  </Text>
                </Button>
              ))}
            </Flex>
            {!hideBalance && !!currency ? (
              <FlexGap gap="6px" width="fit-content" ml="auto" onClick={onMax}>
                <Text fontSize={['12px', , , , '16px']} ml="auto">
                  {t('Balance:')}
                </Text>
                <Text fontSize={['12px', , , , '16px']} ml="auto">
                  {formatNumber(selectedCurrencyBalance?.toSignificant(6), decimal) ?? t('Loading')}
                </Text>
              </FlexGap>
            ) : (
              ' -'
            )}
          </Flex>
        </>
      )}
    </Box>
  )
}

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm', mr: '5px' })`
  padding: 0;
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  height: 100%;
`
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  z-index: 1;
`
const Container = styled.div`
  border-radius: 10px;
  background: var(--color-pink);
  height: 50px;
`

const ButtonCurrency = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 0px 0px 0px;
  border: 0;
`

const PositionButton = styled(Button).attrs({ variant: 'text' })`
  position: absolute;
  top: 2px;
  right: 0;
`

const MaxButton = styled.button`
  background: transparent;
  outline: none;
  border: unset;
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
`

const StyledInput = styled(NumericalInput)`
  padding: 0 85px 0 16px;
`
