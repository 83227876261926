import { getLpContract } from 'utils/contractHelpers'
import { useEffect, useCallback, useState } from 'react'

const CONTRACT_LP = '0x0000000000000000000000000000000000000000'

const useGetLpContract = () => {
  const lpContract = getLpContract(CONTRACT_LP)
  const [rewards, setRewards] = useState([])

  const get = useCallback(async () => {
    const data = await lpContract.getReserves()
    return data
  }, [lpContract])

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await get()
        setRewards(result)
        return result
      } catch (error) {
        console.error(error)
        return null
      }
    }

    loadData()
  }, [])

  return { rewards }
}

export default useGetLpContract
